export const FILTER_QUERY = 'filters/FILTER_QUERY';
export const FILTER_SET = 'filters/FILTER_SET';
export const FILTER_DATE_RANGE = 'filters/FILTER_DATE_RANGE';
export const FILTER_STATUS = 'filters/FILTER_STATUS';

const initialState = {
    queryString: '',
    startDate: null,
    endDate: null,
    statusQuery: null,
    querySet: {},
};

// always combine as listFilters
export default function (state = initialState, action) {
    switch (action.type) {
        case FILTER_QUERY:
            return { ...state, queryString: action.query.toLowerCase() };
        case FILTER_SET:
            return { ...state, querySet: action.querySet };
        case FILTER_DATE_RANGE:
            return {
                ...state,
                endDate: action.endDate,
                startDate: action.startDate,
            };
        case FILTER_STATUS:
            return { ...state, statusQuery: action.statusQuery };
        default:
            return state;
    }
}

export const filterByQueryString = (query) => ({
    type: FILTER_QUERY,
    query,
});

export const filterByFilterSet = (querySet) => ({
    type: FILTER_SET,
    querySet,
});

export const filterByDateRange = (startDate, endDate) => ({
    type: FILTER_DATE_RANGE,
    startDate,
    endDate,
});

export const filterByStatus = (statusQuery) => ({
    type: FILTER_STATUS,
    statusQuery,
});

export const selectFilterQueryArgs = (state) => {
    const { startDate, endDate, queryString, querySet } = state.listFilters;
    const fetchQuery = {};
    if (startDate) {
        fetchQuery.start_date = startDate;
    }
    if (endDate) {
        fetchQuery.end_date = endDate;
    }
    if (queryString) {
        fetchQuery.query_string = queryString;
    }
    if (Object.keys(querySet).length) {
        fetchQuery.filter_cluster = JSON.stringify(querySet);
    }
    return fetchQuery;
};
