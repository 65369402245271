import { all } from 'redux-saga/effects';

import { watchPartOperationsView } from 'parts/operations/sagas/operationsViewSaga';
import {
    watchStartTimeTracker,
    watchPauseTimeTracker,
    watchStopTimeTracker,
} from 'parts/operations/sagas/timeTrackers';
import {
    watchAffixDowntime,
    watchSolveDowntime,
} from 'parts/operations/sagas/downtimes';

export default function* rootSaga() {
    yield all([
        watchPartOperationsView(),
        watchStartTimeTracker(),
        watchPauseTimeTracker(),
        watchStopTimeTracker(),
        watchAffixDowntime(),
        watchSolveDowntime(),
    ]);
}
