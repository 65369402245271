/**
 * The statuses that an order can have.
 * NOTE: Keep up-to-date with BaseOrder models' ORDER_STATUS_CHOICES.
 */
export const ORDER_STATUS = {
    PIPES_NEW: 0,
    PIPES_PLANNED: 1,
    PIPES_COMPLETED: 2,
    PIPES_DEACTIVATED: 3,
    PARTS_UNPLANNED: 4,
    PARTS_PARTIALLY_PLANNED: 5,
    PARTS_PLANNED: 6,
    PARTS_PARTIALLY_COMPLETED: 7,
    PARTS_COMPLETED: 8,
};

export const PIPE_PRODUCT_STATUS = {
    PROD_STATUS_NEW: 0,
    PROD_STATUS_IN_PROGRESS: 1,
    PROD_STATUS_COMPLETED: 2,
    PROD_STATUS_PAUSED: 3,
};

export const PART_PRODUCT_STATUS = {
    PROD_STATUS_UNPLANNED: 0,
    PROD_STATUS_PARTIALLY_PLANNED: 1,
    PROD_STATUS_PLANNED: 2,
    PROD_STATUS_COMPLETED: 3,
};
