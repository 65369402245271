import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { workOrderDenormalizedPropType } from 'parts/workplan/propTypes';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import CollapsibleItem from 'core/components/CollapsibleItem';
import { workOrdersSelectors } from 'parts/workplan/ducks/workOrders';
import { mapPartProductsAPIResponseToActionGroupsStoreFormat } from 'parts/utils/parts';
import { getWorkPlanIncludedInActionGroup } from 'core/timeplan/utils/calendar';
import { useDJConst } from 'core/components/DJConstContext';
import { PartProductPropType } from 'parts/propTypes';
import { partProductsSelectors } from 'parts/operations/ducks/partProducts';
import { workOrderSchema } from 'parts/operations/sagas/operationsViewSaga';
import { workOrderActionsUpdateRoutine } from 'parts/operations/sagas/workOrderActionsSaga';
import {
    WORK_ORDER_ACTION_STATUSES,
    WORK_ORDER_STATUS,
} from 'parts/workplan/constants';
import {
    timeTrackersSelectors,
    stopTimeTrackerRoutine,
} from 'parts/operations/ducks/timeTrackers';
import { timeTrackerPropType } from 'parts/operations/propTypes';
import RenderPropConfirmModal from 'core/components/RenderPropConfirmModal';
import { gettext } from 'core/utils/text';
import styles from 'core/components/CollapsibleItem/CollapsibleItem.scss';

import { WorkOrderHeader, OperationsListWorkPlanTree, BottomBar } from '.';

const WorkOrder = ({
    workOrderId,

    // From Redux
    workOrder,
    workOrderStatus,
    partProduct,
    updateWorkOrders,
    latestTracker,
    stopTimeTracker,
}) => {
    const DJConst = useDJConst();

    const isRunning = workOrderStatus === WORK_ORDER_STATUS.RUNNING;
    const isPaused = workOrderStatus === WORK_ORDER_STATUS.PAUSED;
    const isFinished = workOrderStatus === WORK_ORDER_STATUS.DONE;

    const [editedStatuses, setEditedStatuses] = useState({});
    const [originalStatuses, setOriginalStatuses] = useState({});

    const newStatusesWillBeDone = Object.keys(originalStatuses).every((id) =>
        [editedStatuses[id], originalStatuses[id]].includes(
            WORK_ORDER_ACTION_STATUSES.DONE,
        ),
    );

    const resetStatuses = () => {
        setEditedStatuses({});
        setOriginalStatuses(
            workOrder.work_order_actions.reduce((acc, workOrderAction) => {
                acc[workOrderAction.id] = workOrderAction.status;
                return acc;
            }, {}),
        );
    };

    // Any time the statuses of the actions change in the Redux store, we want
    // to reset the edited statuses so that if the user saves or presses "mark
    // all done", then the selected statuses would be up-to-date
    useEffect(
        () => {
            resetStatuses();
        },
        workOrder.work_order_actions.map((woAction) => woAction.status),
    );

    const changeStatus = (workOrderActionIds, newStatus) => {
        // Update Edited status, and remove any Ids from the object if they are reverting to old status
        setEditedStatuses(
            Object.fromEntries(
                Object.entries({
                    ...editedStatuses,
                    ...Object.fromEntries(
                        workOrderActionIds
                            .filter(
                                (id) =>
                                    originalStatuses[id] !==
                                    WORK_ORDER_ACTION_STATUSES.DONE,
                            )
                            .map((id) => [id, newStatus]),
                    ),
                }).filter(
                    ([id, editingStatus]) =>
                        originalStatuses[id] !== editingStatus,
                ),
            ),
        );
    };

    const hasModifiedStatus = !!Object.keys(editedStatuses).length;

    const handleSave = () => {
        if (newStatusesWillBeDone && isRunning) {
            // If there is a running tracker, then we need to stop it since the
            // work order will dissapear
            stopTimeTracker({ id: latestTracker.id });
        } else {
            const changes = Object.entries(
                editedStatuses,
            ).map(([id, status]) => ({ id, status }));
            updateWorkOrders({ changes });
        }
    };

    const handleMarkAllDone = () => {
        if (isRunning) {
            // If there is a running tracker, then we need to stop it
            stopTimeTracker({ id: latestTracker.id });
        } else {
            // Otherwise just mark all actions as done
            const changes = workOrder.work_order_actions
                .filter(
                    (woAction) =>
                        woAction.status !== WORK_ORDER_ACTION_STATUSES.DONE,
                )
                .map((woAction) => ({
                    id: woAction.id,
                    status: WORK_ORDER_ACTION_STATUSES.DONE,
                }));

            updateWorkOrders({ changes });
        }
    };

    const actionGroups = mapPartProductsAPIResponseToActionGroupsStoreFormat([
        partProduct,
    ]);
    const workPlan = Object.values(actionGroups)
        .filter((bubble) => bubble.workOrder?.id === workOrderId)
        .map((bubble) =>
            getWorkPlanIncludedInActionGroup(
                partProduct,
                bubble,
                DJConst.BODY_CUTTING_ACTION_ID,
                DJConst.BODY_EXPANSION_ACTION_ID,
            ),
        )[0];

    const confirmModalText = newStatusesWillBeDone
        ? // eslint-disable-next-line max-len
          gettext(
              'All work related to this work order will be marked as "Done" and the work order will disappear from your work planner.',
          )
        : gettext(
              'All selected jobs and their subparts will be marked as "Done" and cannot be reversed.',
          );

    const confirmModalTitle = newStatusesWillBeDone
        ? gettext('Are you sure you want to stop working on this work order?')
        : gettext('Are you sure you want to do this?');

    return !isFinished ? (
        <CollapsibleItem
            key={workOrder.id}
            header={(isCollapsed) => (
                <WorkOrderHeader
                    workOrder={workOrder}
                    partProduct={partProduct}
                    isExpanded={!isCollapsed}
                />
            )}
            collapsedInitially
        >
            <OperationsListWorkPlanTree
                workPlan={workPlan}
                editedStatuses={editedStatuses}
                originalStatuses={originalStatuses}
                changeStatus={changeStatus}
                workOrder={workOrder}
                workOrderStatus={workOrderStatus}
                onMarkAllDone={handleMarkAllDone}
                readOnly={!(isRunning || isPaused)}
            />
            <RenderPropConfirmModal
                title={confirmModalTitle}
                onConfirm={handleSave}
                modalContent={<p>{confirmModalText}</p>}
            >
                {({ requestConfirm }) =>
                    (isRunning || isPaused) && (
                        <BottomBar
                            onSave={requestConfirm}
                            onCancel={resetStatuses}
                            disableButtons={!hasModifiedStatus}
                        />
                    )
                }
            </RenderPropConfirmModal>
        </CollapsibleItem>
    ) : (
        // Finished Work Order has never been supposed to be visible.
        // It uses the same logic as timeplan's bubbles which are gone when completed.
        // Thus, we can't display work plan for it as it doesn't exist.
        // I don't want even try to change it and hope I will not have to. Never.
        <div className={styles.listItem}>
            <WorkOrderHeader
                workOrder={workOrder}
                partProduct={partProduct}
                isExpanded={false}
            />
        </div>
    );
};

WorkOrder.propTypes = {
    workOrderId: PropTypes.number.isRequired,
    workOrder: workOrderDenormalizedPropType.isRequired,
    workOrderStatus: PropTypes.oneOf(Object.values(WORK_ORDER_STATUS))
        .isRequired,
    partProduct: PartProductPropType.isRequired,
    updateWorkOrders: PropTypes.func.isRequired,
    latestTracker: timeTrackerPropType,
    stopTimeTracker: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const workOrder = workOrdersSelectors.getById(state, ownProps.workOrderId);

    // Prepare entities for denormalizing, maybe this should be a utility
    // function?
    const entities = {};
    Object.entries(state.entities).forEach(([entityKey, entity]) => {
        entities[entityKey] = entity.byId;
    });

    const denormalizedWorkOrder = denormalize(
        workOrder,
        workOrderSchema,
        entities,
    );

    const partProduct = partProductsSelectors.getById(
        state,
        workOrder.part_product,
    );

    return {
        workOrder: denormalizedWorkOrder,
        partProduct: {
            ...partProduct,
            // Attach the work plan with a different property name since it's
            // required in some of the utility functions we call
            workPlan: partProduct.work_plan_json,
        },
        workOrderStatus: workOrdersSelectors.getWorkOrderStatus(
            state,
            ownProps.workOrderId,
        ),
        latestTracker: timeTrackersSelectors.getNewestTracker(
            state,
            ownProps.workOrderId,
        ),
    };
};

const mapDispatchToProps = {
    updateWorkOrders: workOrderActionsUpdateRoutine,
    stopTimeTracker: stopTimeTrackerRoutine,
};

const WorkOrderConnector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WorkOrder);

export default WorkOrderConnector;
