import { call, put, takeEvery } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import api from 'core/utils/api';

import {
    startTimeTrackerRoutine,
    pauseTimeTrackerRoutine,
    stopTimeTrackerRoutine,
} from '../ducks/timeTrackers';
import { workOrderSchema } from './operationsViewSaga';

export function* watchStartTimeTracker() {
    yield takeEvery(
        startTimeTrackerRoutine.TRIGGER,
        function* saga({ payload }) {
            try {
                const data = yield call(() =>
                    api.timeTrackers.list.post(null, payload),
                );
                yield put(
                    startTimeTrackerRoutine.success({ timeTracker: data }),
                );
            } catch (error) {
                console.error(error);
                yield put(startTimeTrackerRoutine.failure(error));
            }
        },
    );
}

export function* watchPauseTimeTracker() {
    yield takeEvery(
        pauseTimeTrackerRoutine.TRIGGER,
        function* saga({ payload }) {
            try {
                const data = yield call(() =>
                    api.timeTrackers.pause.patch({ pk: payload.id }),
                );
                yield put(
                    pauseTimeTrackerRoutine.success({ timeTracker: data }),
                );
            } catch (error) {
                console.error(error);
                yield put(pauseTimeTrackerRoutine.failure(error));
            }
        },
    );
}

export function* watchStopTimeTracker() {
    yield takeEvery(
        stopTimeTrackerRoutine.TRIGGER,
        function* saga({ payload }) {
            try {
                const data = yield call(() =>
                    api.timeTrackers.stop.patch({
                        pk: payload.id,
                    }),
                );

                const { entities: workOrderEntities } = normalize(
                    data.work_order,
                    workOrderSchema,
                );

                yield put(
                    stopTimeTrackerRoutine.success({
                        timeTracker: data.time_tracker,
                        workOrders: workOrderEntities.workOrders,
                        workOrderActions: workOrderEntities.workOrderActions,
                    }),
                );
            } catch (error) {
                console.error(error);
                yield put(stopTimeTrackerRoutine.failure(error));
            }
        },
    );
}
