import { gettext } from './text';

const translations = {
    weight: gettext('Weight'),
    inner_color: gettext('Inner color'),
    config: gettext('Diameter'),
    length_produced: gettext('Length produced'),
    length_ordered: gettext('Length ordered'),
    inner_thicknesses: gettext('Profile Gap'),
    outer_thicknesses: gettext('Profile Thickness'),
    rib_caps: gettext('Rib Gaps'),
    socket_diameter: gettext('Socket Diameter'),
    socket_thicknesses: gettext('Socket Thickness'),
    spigot_diameter: gettext('Spigot Diameter'),
    type: gettext('Profile type'),
    TYPE: gettext('Profile type'),
    core_tube_config: gettext('Profile core diameter'),
    moment_of_inertia: gettext('Moment of inertia'),
    end_front: gettext('Pipe front end'),
    end_back: gettext('Pipe back end'),
    ring: gettext('Ring'),
    operator: gettext('Operator'),
    directo_entry_date: gettext('Directo entry date'),
    directo_reentry_date: gettext('Directo reentry date'),
    status: gettext('Status'),
    production_status: gettext('Status'),
    is_special: gettext('Special'),
    is_DET: gettext('DET'),
    is_polymark: gettext('Polymark'),
    RC: gettext('RC'),
    grinder: gettext('Grinder'),
    finisher: gettext('Finisher'),
    finisher_date: gettext('Finisher date'),
    welding_installer: gettext('Welding installer'),
    measurer: gettext('Measurer'),
    measurer_date: gettext('Measurer date'),
    client_name: gettext('Client name'),
    client_name_short: gettext('Customer'),
    order_number: gettext('Order number'),

    // orders
    client: gettext('Client'),
    number: gettext('Number'),
    date: gettext('Date'),
    worker: gettext('Worker'),
    last_modified: gettext('Timestamp'),
    deactivated_pipes: gettext('Has deactivated pipes'),

    exists: gettext('exists'),
    missing: gettext('missing'),
    without: gettext('without'),
    true: gettext('true'),
    false: gettext('false'),
    equals: gettext('equals'),
    not_equals: gettext('not equals'),
    less_than: gettext('less than'),
    less_than_equal: gettext('less than equal'),
    greater_than: gettext('greater than'),
    greater_than_equal: gettext('greater than equal'),
    in_range: gettext('in range'),
    less_than_allowed: gettext('less than allowed'),
    greater_than_allowed: gettext('greater than allowed'),
    inside_allowed: gettext('inside allowed'),
    outside_allowed: gettext('outside allowed'),
    all_pp_rc: gettext('all PPRC'),
    all_pe_rc: gettext('all PERC'),
    contains: gettext('contains'),
    not_contains: gettext('not contains'),

    // operations
    diameter: gettext('Diameter'),
    sn_class: gettext('SN'),
    quantity: gettext('Quantity'),
    tools: gettext('Tools'),
    plan_value: gettext('RC %'),
    comment: gettext('Comment'),
    order: gettext('Order'),
    start_time: gettext('Start time'),
    end_time: gettext('End time'),
    actual_start_time: gettext('Actual start'),
    actual_end_time: gettext('Actual end'),
    profile: gettext('Profile'),
    length: gettext('Length'),
    ends: gettext('Ends'),

    /**
     * Seems that gettext is having issues parsing some JS files, some of these translations
     * Some of the following translatiosn rae not directly being referanced, but they do have a matching gettext in krah
     * They are here as well so make-messages works.
     */

    body_cutting: gettext('Body cutting'),
    body_expansion: gettext('Body expansion'),

    loading: gettext('Loading...'),
    pipe_diameter_mm: gettext('Pipe diameter (mm)'),
    expansion_count: gettext('Expansion count'),
    expansion_operator: gettext('Expansion operator'),
    needs_cutting: gettext('Needs cutting'),
    cutting_operator: gettext('Cutting operator'),

    directo_date: gettext('Directo date'),
    edit_pipe: gettext('Edit pipe'),
    edit_selected_part: gettext('Edit selected part'),
    extra_info_for_operator: gettext('Extra info for operator'),
    forecast_amounts: gettext('Forecast amounts'),
    forecast_for_produced_products: gettext('Forecast for produced products'),
    friday: gettext('Friday'),
    input_in_focus: gettext('Input in focus'),
    insert_tools: gettext('Insert tools'),
    last_change: gettext('Last change'),
    lines: gettext('Lines:'),
    loading_more_orders: gettext('Loading more orders..'),
    mark_all_done: gettext('Mark all done'),
    max_pipes_per_day: gettext('Max pipes per day'),
    measurement: gettext('Measurement:'),
    Minutes: gettext('Minutes'),
    minutes: gettext('minutes'),
    Missing: gettext('Missing'),
    monday: gettext('Monday'),
    natural_percentage: gettext('Natural percentage: %s%'),
    next_shift: gettext('Next shift'),
    no_action_chosen: gettext('No action chosen.'),
    no_comment_added: gettext('No comment added'),
    now: gettext('Now'),
    overtime: gettext('Overtime'),
    perc_pprc: gettext('PERC/PPRC'),
    paint_percentage: gettext('Paint percentage: %s%'),
    pair_working: gettext('Pair-working'),
    period_end: gettext('Period end'),
    period_start: gettext('Period start'),
    pieces: gettext('Pieces'),
    print_label: gettext('Print label'),
    products_with_core_tube: gettext('Products with core tube'),
    products_without_core_tube: gettext('Products without core tube'),
    profile_time: gettext('Profile time'),
    reload: gettext('Reload'),
    remove_selected_parts_from_the_calendar: gettext(
        'Remove selected parts from the calendar',
    ),
    revoke_selected_worker_from_workplan: gettext(
        'Revoke selected worker from workplan',
    ),
    saturday: gettext('Saturday'),
    save_template: gettext('Save template'),
    scanner_ready: gettext('Scanner ready'),
    scrap_rate: gettext('Scrap rate: %s%'),
    scrolling: gettext('Scrolling'),
    sunday: gettext('Sunday'),
    that_action_has_already_been_assigned_to_the_worker: gettext(
        'That action has already been assigned to the worker.',
    ),
    this_view_gives_the_overview_of_pipes_produced: gettext(
        'This view gives the overview of pipes produced.',
    ),
    thursday: gettext('Thursday'),
    total_amounts: gettext('Total amounts'),
    tuesday: gettext('Tuesday'),
    wednesday: gettext('Wednesday'),
    zoom_out: gettext('Zoom out'),
    changes: gettext('changes'),
    hours: gettext('hours'),
    mark_as_completed: gettext('Mark as completed'),
    in_directo: gettext('In Directo'),

    material_forcast: gettext(
        'This view gives the forecast of material costs for products that have been planned',
    ),

    PIPE: gettext('PIPE'),
    PART: gettext('PART'),

    // EditPipeForm.js
    Avg: gettext('Avg'),

    'Directo Article': gettext('Directo Article'),

    'Directo Date': gettext('Directo Date'),

    'Inner Thickness Required': gettext('Inner Thickness Required'),

    'Inner Thickness Tolerance': gettext('Inner Thickness Tolerance'),

    'Length required': gettext('Length required'),

    'Length tolerance': gettext('Length tolerance'),

    Max: gettext('Max'),

    'Max Outer Diameter': gettext('Max Outer Diameter'),

    'Measuring Time': gettext('Measuring Time'),

    Min: gettext('Min'),

    Notes: gettext('Notes'),

    'Operation comment': gettext('Operation comment'),

    'Outer Thickness Required': gettext('Outer Thickness Required'),

    'Outer Thickness Tolerance': gettext('Outer Thickness Tolerance'),

    'PE RC%': gettext('PE RC%'),

    'PP RC%': gettext('PP RC%'),

    'Pipe ID': gettext('Pipe ID'),

    'Rib Cap Required': gettext('Rib Cap Required'),

    'Rib Cap Tolerance': gettext('Rib Cap Tolerance'),

    'Roundness Allowed': gettext('Roundness Allowed'),

    'Roundness End': gettext('Roundness End'),

    'Roundness Max Diameter': gettext('Roundness Max Diameter'),

    'Roundness Min Diameter': gettext('Roundness Min Diameter'),

    'SN class': gettext('SN class'),

    'Socket Diameter Required': gettext('Socket Diameter Required'),

    'Socket Diameter Tolerance': gettext('Socket Diameter Tolerance'),

    'Socket Thickness Required': gettext('Socket Thickness Required'),

    'Socket Thickness Tolerance': gettext('Socket Thickness Tolerance'),

    'Special recipe comment': gettext('Special recipe comment'),

    'Spigot Diameter Required': gettext('Spigot Diameter Required'),

    'Spigot Diameter Tolerance': gettext('Spigot Diameter Tolerance'),

    Standard: gettext('Standard'),

    Tags: gettext('Tags'),

    'Weight suggested': gettext('Weight suggested'),
    Overview: gettext('Overview'),
    General: gettext('General'),
    Curvature: gettext('Curvature'),
    Socket: gettext('Socket'),
    Spigot: gettext('Spigot'),
    Technical: gettext('Technical'),
    Ribs: gettext('Ribs'),
    Save: gettext('Save'),
    Cancel: gettext('Cancel'),
    Refresh: gettext('Refresh'),
    'Changes History': gettext('Changes History'),

    'Changes saved successfully': gettext('Changes saved successfully'),

    'Measurement results': gettext('Measurement results'),

    'Overview & Pipe Result': gettext('Overview & Pipe Result'),

    'Pipe result': gettext('Pipe result'),

    'Saving..': gettext('Saving..'),

    'Spigot & Socket': gettext('Spigot & Socket'),
};

export default translations;
