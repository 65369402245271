import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const TooltipOverlay = ({
    tooltipKey,
    tooltipPlacement,
    tooltipText,
    children,
}) => (
    <OverlayTrigger
        key={tooltipKey}
        placement={tooltipPlacement}
        overlay={
            <Tooltip id={`tooltip-${tooltipPlacement}`}>{tooltipText}</Tooltip>
        }
    >
        {children}
    </OverlayTrigger>
);

TooltipOverlay.propTypes = {
    tooltipKey: PropTypes.string.isRequired,
    tooltipPlacement: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default TooltipOverlay;
