import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import {
    clientPropType,
    orderPropType,
    articleGroupPropType,
    partProductPropType,
} from 'core/timeplan/propTypes';
import {
    COMPONENT_TYPE_VALUES,
    COMPONENT_TYPES,
    WORK_ORDER_ACTION_STATUSES,
    WORK_ORDER_STATUS,
} from './constants';

export const BasePartPropType = {
    id: PropTypes.number.isRequired,
    H_W_S: PropTypes.number,
    OD_L: PropTypes.number,
    W_H_D: PropTypes.number,
    additional_time: PropTypes.number,
    pieces: PropTypes.number,
};

export const workPlanActionPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    action_name: PropTypes.string.isRequired,
    worker_id: PropTypes.number,
    worker_number: PropTypes.number,
});

export const workPlanPartPropType = PropTypes.shape({
    ...BasePartPropType,
    actions: PropTypes.arrayOf(workPlanActionPropType),
});

// The data format in PartProduct.work_plan_json
export const ComponentPropType = PropTypes.shape({
    type: PropTypes.oneOf(COMPONENT_TYPE_VALUES).isRequired,
    verbose: PropTypes.oneOf(COMPONENT_TYPES).isRequired,
    parts: PropTypes.arrayOf(workPlanPartPropType).isRequired,

    // Only on body:
    diameter: PropTypes.number,
    needs_cutting: PropTypes.bool,
    expansion_count: PropTypes.number,
    expansion_type: PropTypes.number,
});

export const componentPartPropType = PropTypes.shape({
    ...BasePartPropType,
    part_name: PropTypes.string.isRequired,
});

const actionWithPlanPartPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    action_name: PropTypes.string.isRequired,
    worker_id: PropTypes.number,
    worker_number: PropTypes.number,
    planPart: componentPartPropType,
});

export const actionSerializedPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    part_type_id: PropTypes.number,
    used_variables: PropTypes.arrayOf(PropTypes.string).isRequired,
    formula: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    work_type: PropTypes.number.isRequired,
    archived: PropTypes.bool.isRequired,
});

const workOrderShape = {
    id: PropTypes.number,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    part_product: PropTypes.number.isRequired,
    worker: PropTypes.number.isRequired,
    work_order_actions: PropTypes.arrayOf(PropTypes.number).isRequired,
    status: PropTypes.oneOf(Object.values(WORK_ORDER_STATUS)).isRequired,
};

export const workOrderPropType = PropTypes.shape(workOrderShape);

const workOrderActionDenormalizedPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.oneOf(Object.values(WORK_ORDER_ACTION_STATUSES))
        .isRequired,
    work_order: PropTypes.number.isRequired,
    action: actionSerializedPropType.isRequired,
});

export const workOrderDenormalizedPropType = PropTypes.shape({
    ...workOrderShape,
    work_order_actions: PropTypes.arrayOf(workOrderActionDenormalizedPropType)
        .isRequired,
});

export const workOrderWithActionsPropType = PropTypes.shape({
    ...workOrderShape,
    workOrderActions: PropTypes.arrayOf(actionSerializedPropType).isRequired,
});

export const actionGroupPropType = PropTypes.shape({
    actions: PropTypes.arrayOf(actionWithPlanPartPropType).isRequired,
    id: PropTypes.string.isRequired,
    partProductId: PropTypes.number.isRequired,
    workerId: PropTypes.number,
    label: PropTypes.string,
});

export const actionGroupWithAllInfoPropType = PropTypes.shape({
    actions: PropTypes.arrayOf(actionWithPlanPartPropType).isRequired,
    id: PropTypes.string.isRequired,
    partProductId: PropTypes.number.isRequired,
    workerId: PropTypes.number,
    client: clientPropType.isRequired,
    order: orderPropType.isRequired,
    articleGroup: articleGroupPropType.isRequired,
    partProduct: partProductPropType.isRequired,
    label: PropTypes.string,
});

export const blockerPropType = PropTypes.shape({
    start: momentPropTypes.momentObj.isRequired,
    end: momentPropTypes.momentObj.isRequired,
});

export const partTypeSerializedPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    component_type: PropTypes.number.isRequired,
    last_work_plan: workPlanPartPropType,
});

export const componentTagPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number,
});

export const workPlanComponentPropType = PropTypes.shape({
    type: PropTypes.oneOf(COMPONENT_TYPE_VALUES).isRequired,
    verbose: PropTypes.string.isRequired,
    parts: PropTypes.arrayOf(workPlanPartPropType).isRequired,
    component_tags: PropTypes.arrayOf(componentTagPropType),

    // Only for BODY components:
    cutting_operator: PropTypes.number,
    diameter: PropTypes.number,
    expansion_count: PropTypes.number,
    expansion_operator: PropTypes.number,
    expansion_type: PropTypes.number,
    needs_cutting: PropTypes.bool,
});

export const workPlanPropType = PropTypes.arrayOf(workPlanComponentPropType);
