import { call, put, takeEvery } from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';

import api from 'core/utils/api';
import {
    stationsRoutine,
    STATION_TYPES,
    STATE_KEY,
} from 'stations/ducks/stations';

const stationsFilter = {
    station_type__in: `${STATION_TYPES.COOLING},${STATION_TYPES.OPERATION}`,
};
const stationsSchema = new schema.Entity(STATE_KEY);

function* fetchStationsAsync() {
    try {
        const data = yield call(() =>
            api.stations.list.fetch(null, stationsFilter),
        );
        const { entities, result } = normalize(data, [stationsSchema]);
        yield put(
            stationsRoutine.success({
                byId: entities[STATE_KEY],
                allIds: result,
            }),
        );
    } catch (error) {
        console.error(error);
        yield put(stationsRoutine.failure(error));
    }
}

export function* watchFetchStations() {
    yield takeEvery(stationsRoutine.REQUEST, fetchStationsAsync);
}
