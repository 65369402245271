import { call, put, takeEvery, fork, select } from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';
import { createRoutine } from 'redux-saga-routines';

import api from 'core/utils/api';
import { selectFilterQueryArgs } from 'core/ducks/listFilters';

import { STATE_KEY as PART_PRODUCTS_KEY } from '../ducks/partProducts';
import { STATE_KEY as TIME_TRACKERS_KEY } from '../ducks/timeTrackers';
import { STATE_KEY as DOWNTIMES_KEY } from '../ducks/downtimes';
import { STATE_KEY as ACTIONS_KEY } from '../ducks/actions';
import { watchWorkOrderActions } from './workOrderActionsSaga';

export const STATE_KEY = 'parts/operations';
export const operationsPageRoutine = createRoutine(STATE_KEY);

const dataReceived = ({
    workOrders,
    partProducts,
    workOrderIds,
    workOrderActions,
    actions,
    timeTrackers,
    downtimes,
}) => ({
    type: operationsPageRoutine.SUCCESS,
    payload: {
        workOrders,
        workOrderIds,
        workOrderActions,
        actions,
        partProducts,
        timeTrackers,
        downtimes,
    },
});

export const workOrderSchema = new schema.Entity('workOrders', {
    work_order_actions: [
        new schema.Entity('workOrderActions', {
            action: new schema.Entity(ACTIONS_KEY),
        }),
    ],
});

const partProductSchema = new schema.Entity(PART_PRODUCTS_KEY);
const timeTrackerSchema = new schema.Entity(TIME_TRACKERS_KEY);
const downtimeSchema = new schema.Entity(DOWNTIMES_KEY);

function* fetchPartOperationsDataAsync() {
    try {
        const filterQuery = yield select(selectFilterQueryArgs);

        const workOrdersData = yield call(() =>
            api.workPlan.partWorkOrders.fetch(null, { ...filterQuery }),
        );
        const {
            entities: workOrderEntities,
            result: workOrderIds,
        } = normalize(workOrdersData, [workOrderSchema]);

        if (!workOrdersData.length) {
            yield put(
                dataReceived({
                    workOrders: {},
                    workOrderIds: [],
                    workOrderActions: {},
                    actions: {},
                    partProducts: {},
                    timeTrackers: {},
                    downtimes: {},
                }),
            );
            return;
        }

        // we need to get partProducts and timers if we have any assigned
        // workOrders
        const partProductIds = Object.values(workOrderEntities.workOrders).map(
            (wo) => wo.part_product,
        );
        const partProducts = yield call(() =>
            api.partProducts.list.fetch(null, {
                id__in: partProductIds.join(','),
            }),
        );
        const { entities: partProductEntities } = normalize(partProducts, [
            partProductSchema,
        ]);

        const timeTrackers = yield call(
            [api.timeTrackers.list, 'fetch'],
            null,
            { work_order_id__in: workOrderIds.join(',') },
        );
        const { entities: timeTrackerEntities } = normalize(timeTrackers, [
            timeTrackerSchema,
        ]);

        const downtimes = yield call([api.downtimes.list, 'fetch'], null, {
            work_order_id__in: workOrderIds.join(','),
        });
        const { entities: downtimesEntities } = normalize(downtimes, [
            downtimeSchema,
        ]);

        yield put(
            dataReceived({
                workOrders: workOrderEntities.workOrders,
                workOrderIds,
                workOrderActions: workOrderEntities.workOrderActions,
                actions: workOrderEntities.actions,
                partProducts: partProductEntities.partProducts,
                timeTrackers: timeTrackerEntities.timeTrackers,
                downtimes: downtimesEntities.downtimes,
            }),
        );
    } catch (error) {
        console.error(error);
        yield put(operationsPageRoutine.failure(error));
    }
}

export function* watchPartOperationsView() {
    // Watch for work order action related actions
    yield fork(watchWorkOrderActions);

    // we start with getting workOrders for the current user
    yield takeEvery(
        operationsPageRoutine.trigger,
        fetchPartOperationsDataAsync,
    );
}
