import PropTypes from 'prop-types';
import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import translations from 'core/utils/translations';
import locale from 'core/utils/dateLocalization';
import dateFilterRanges from '../../utils/dateFilterRanges';

export default class extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        initial: PropTypes.object,
    };

    constructor(props) {
        super(props);
        const initialKey = props.initial ? Object.keys(props.initial)[0] : null;
        const initialValues = props.initial ? props.initial[initialKey] : null;

        const filters = Object.keys(DJ_CONST.FILTERS);
        const first = initialKey || filters[0];
        const filter = DJ_CONST.FILTERS[first];
        const inputChoices = filter.choices;
        const initialChoice = inputChoices ? inputChoices[0][0] : null;

        let defaultOperator = -1;
        if (filter.operators.length === 1) {
            defaultOperator = filter.operators[0];
        }
        this.state = {
            filters,
            fieldName: first,
            fieldOperator: initialValues
                ? initialValues.operator
                : defaultOperator,
            fieldType: filter.type,
            choices: inputChoices,
            inputOne: initialValues ? initialValues.value : initialChoice,
            inputTwo: initialChoice,
        };

        this.update = debounce(
            () => this.props.onChange(this.packFilter()),
            400,
        );
    }

    getChoices(fieldName, fieldOperator) {
        const { choices } = DJ_CONST.FILTERS[fieldName];
        const specialMap = DJ_CONST.FILTERS[fieldName].special;
        const specialInputChoices = specialMap
            ? specialMap[fieldOperator] || specialMap.default
            : null;
        return choices || specialInputChoices;
    }

    getFieldDisplayName(fieldName) {
        const fieldNameSplit = fieldName.split('__');
        return fieldNameSplit[fieldNameSplit.length - 1];
    }

    fieldNameOptions() {
        return this.state.filters.map((key) => (
            <option key={key} value={key}>
                {translations[this.getFieldDisplayName(key)]}
            </option>
        ));
    }

    fieldOperatorOptions() {
        const ops = DJ_CONST.FILTERS[this.state.fieldName].operators;
        return ops.map((key) => (
            <option key={key} value={key}>
                {translations[key]}
            </option>
        ));
    }

    filterInput() {
        const noInputFilters = [
            'exists',
            'missing',
            'less_than_allowed',
            'greater_than_allowed',
            'inside_allowed',
            'outside_allowed',
            'true',
            'false',
            'all_pp_rc',
            'all_pe_rc',
        ];

        if (
            this.state.fieldOperator === -1 ||
            noInputFilters.includes(this.state.fieldOperator)
        ) {
            return null;
        }

        const inputHelper = (key) => {
            if (this.state.choices) {
                return (
                    <select
                        value={this.state[key]}
                        className="search-input"
                        onChange={(event) => this.handleInputChange(key, event)}
                    >
                        {this.state.choices.map((choice) => (
                            <option key={choice[1]} value={choice[0]}>
                                {choice[1]}
                            </option>
                        ))}
                    </select>
                );
            } else if (this.state.fieldType === 'date') {
                const value =
                    this.state[key] !== '' ? moment(this.state[key]) : null;
                let label = gettext('All dates');
                if (value !== null) {
                    label = value.format('DD.MM.YYYY');
                }
                return (
                    <div className="date-range-picker-wrapper">
                        <DateRangePicker
                            singleDatePicker
                            locale={locale}
                            onApply={(evt, picker) =>
                                this.handleDateChange(key, picker)
                            }
                        >
                            <span className="selected-date-range-btn">
                                {label}
                            </span>
                        </DateRangePicker>
                    </div>
                );
            } else if (this.state.fieldType === 'date-range') {
                let label = gettext('All dates');
                const currentStateValue = this.state[key];
                if (currentStateValue !== '' && currentStateValue !== null) {
                    const value = [
                        moment(currentStateValue[0]).format('DD.MM.YYYY'),
                        moment(currentStateValue[1]).format('DD.MM.YYYY'),
                    ];
                    label = `${value[0]} - ${value[1]}`;
                }

                return (
                    <div className="date-range-picker-wrapper">
                        <DateRangePicker
                            ranges={dateFilterRanges()}
                            locale={locale}
                            onApply={(evt, picker) =>
                                this.handleDateRangeChange(key, picker)
                            }
                        >
                            <span className="selected-date-range-btn">
                                {label}
                            </span>
                        </DateRangePicker>
                    </div>
                );
            } else {
                return (
                    <input
                        className="search-input"
                        type={
                            this.state.fieldType
                                ? this.state.fieldType
                                : 'number'
                        }
                        onChange={(event) => this.handleInputChange(key, event)}
                    />
                );
            }
        };

        return (
            <span>
                {inputHelper('inputOne')}
                {this.state.fieldOperator === 'in_range'
                    ? inputHelper('inputTwo')
                    : null}
            </span>
        );
    }

    handleFieldNameChange(event) {
        const fieldName = event.target.value;
        const filter = DJ_CONST.FILTERS[fieldName];
        const fieldType = filter.type;
        let inputChoices = filter.choices;
        inputChoices =
            inputChoices ||
            this.getChoices(fieldName, this.state.fieldOperator);

        let inputOne = inputChoices ? inputChoices[0][0] : '';
        let inputTwo = inputChoices ? inputChoices[0][0] : '';
        let fieldOperator = -1;

        if (inputChoices && inputChoices === this.state.choices) {
            ({ inputOne } = this.state);
            ({ inputTwo } = this.state);
            ({ fieldOperator } = this.state.fieldOperator);
        } else if (filter.operators.length === 1) {
            fieldOperator = filter.operators[0];
        }

        this.setState(
            {
                fieldName,
                inputOne,
                inputTwo,
                fieldOperator,
                choices: inputChoices,
                fieldType,
            },
            fieldOperator !== -1 ? this.update : null,
        );
    }

    handleFieldOperatorChange(event) {
        const { fieldName } = this.state;
        const fieldOperator =
            parseInt(event.target.value, 10) || event.target.value;
        const choices =
            fieldOperator !== -1
                ? this.getChoices(fieldName, fieldOperator)
                : null;
        const state = { fieldOperator, choices };
        if (choices) {
            state.inputOne = choices[0][0];
        }
        this.setState(state, this.update);
    }

    handleInputChange(name, event) {
        const { value } = event.target;
        this.setState({ [name]: value }, this.update);
    }

    handleDateChange(name, picker) {
        const value = picker.startDate.format('YYYY-MM-DD');
        this.setState({ [name]: value }, this.update);
    }

    handleDateRangeChange(name, picker) {
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');
        this.setState({ [name]: [startDate, endDate] }, this.update);
    }

    packFilter() {
        let value = this.state.inputOne;
        if (this.state.fieldOperator === 'in_range') {
            value = [this.state.inputOne, this.state.inputTwo];
        }
        if (this.state.fieldType === 'date') {
            value = value !== '' ? value : null;
        }
        return {
            [this.state.fieldName]: [
                { operator: this.state.fieldOperator, value },
            ],
        };
    }

    render() {
        return (
            <div>
                <select
                    className="search-input"
                    onChange={(event) => this.handleFieldNameChange(event)}
                    value={this.state.fieldName}
                >
                    {this.fieldNameOptions()}
                </select>

                <select
                    className="search-input"
                    onChange={(event) => this.handleFieldOperatorChange(event)}
                    value={this.state.fieldOperator}
                >
                    <option value={-1}>----</option>
                    {this.fieldOperatorOptions()}
                </select>

                {this.filterInput()}

                <button
                    className="btn btn-primary btn-danger search-button"
                    onClick={this.props.onDelete}
                >
                    {gettext('Remove filter')}
                </button>
            </div>
        );
    }
}
