import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import {
    filterByQueryString,
    filterByFilterSet,
    filterByDateRange,
    filterByStatus,
} from 'core/ducks/listFilters';
import SearchBar from './SearchBar';
import FilterBar from './FilterBar';
import DateFilter from './DateFilter';

class ListFilters extends Component {
    static propTypes = {
        listFilters: PropTypes.shape().isRequired,

        disabled: PropTypes.bool,
        showReview: PropTypes.bool,
        showDateFilter: PropTypes.bool,
        canFilter: PropTypes.bool,
        isDirty: PropTypes.bool,

        beforeFilter: PropTypes.func,
        afterFilter: PropTypes.func,

        // redux state
        queryString: PropTypes.string,
        startDate: momentPropTypes.momentObj,
        endDate: momentPropTypes.momentObj,
        statusQuery: PropTypes.string,

        // redux actions
        filterByStatus: PropTypes.func.isRequired,
        filterByQueryString: PropTypes.func.isRequired,
        filterByDates: PropTypes.func.isRequired,
        filterByFilterSet: PropTypes.func.isRequired,

        canExportData: PropTypes.bool,

        children: PropTypes.node,
    };

    static defaultProps = {
        disabled: false,
        showReview: false,
        showDateFilter: true,
        canFilter: true,
        isDirty: false,
        canExportData: false,
        beforeFilter: () => null,
        afterFilter: () => null,
    };

    componentDidMount() {
        if (DJ_CONST.USE_DEFAULT_DATES) {
            const startDate = moment().startOf('day');
            const endDate = moment().add(1, 'day').endOf('day');
            this.handleFilterDate(null, { startDate, endDate });
        }

        if (DJ_CONST.DEFAULT_FILTERS) {
            this.props.filterByFilterSet(DJ_CONST.DEFAULT_FILTERS.query);
        }
    }

    handleSearch = () => {
        if (this.props.isDirty) {
            return;
        }
        this.props.beforeFilter();
        setTimeout(() => {
            this.props.afterFilter();
        }, 350);
    };

    handleFilterGeneric = (filterFunc) => (...args) => {
        if (this.props.isDirty) {
            return;
        }
        this.props.beforeFilter();
        filterFunc(...args);
        this.props.afterFilter();
    };

    handleFilterStatus = () => {
        const STATUS_REVIEW = 4;
        const statusQueryActive = this.props.statusQuery === null;
        const statusQuery = statusQueryActive ? STATUS_REVIEW : null;
        this.props.filterByStatus(statusQuery);
    };

    handleFilterDate = (event, picker) => {
        this.props.filterByDates(
            picker.startDate.toISOString(),
            picker.endDate.toISOString(),
        );
    };

    handleClearingDateFilter = () => {
        this.props.filterByDates(null, null);
    };

    handleReset = () => {
        if (this.props.endDate || this.props.startDate) {
            this.props.filterByDates(null, null);
        }
        if (this.props.queryString) {
            this.props.filterByQueryString('');
        }
        if (this.props.statusQuery) {
            this.props.filterByStatus(null);
        }
    };

    render() {
        const {
            disabled,
            showReview,
            showDateFilter,
            canFilter,
            isDirty,
        } = this.props;

        const statusQueryActive = this.props.statusQuery === null;
        const statusQueryDisplay = statusQueryActive
            ? gettext('Show pipes for review')
            : gettext('Reset review filter');

        return (
            <div style={disabled ? { cursor: 'not-allowed' } : null}>
                <div style={disabled ? { pointerEvents: 'none' } : null}>
                    {showReview ? (
                        <span
                            className="search-filter pull-right"
                            onClick={this.handleFilterGeneric(
                                this.handleFilterStatus,
                            )}
                        >
                            {statusQueryDisplay}
                        </span>
                    ) : null}
                    <SearchBar
                        queryString={this.props.queryString}
                        onChange={this.props.filterByQueryString}
                    />
                    {showDateFilter ? (
                        <DateFilter
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            onClearFilter={this.handleClearingDateFilter}
                            onChange={this.handleFilterDate}
                        />
                    ) : null}
                    {canFilter ? (
                        <div>
                            <FilterBar
                                onChange={this.props.filterByFilterSet}
                                onReset={this.handleFilterGeneric(
                                    this.handleReset,
                                )}
                                isDirty={isDirty}
                                listFilters={this.props.listFilters}
                                canExportData={this.props.canExportData}
                            >
                                {this.props.children}
                            </FilterBar>
                        </div>
                    ) : null}
                </div>
                <button
                    className="btn btn-black search-button-apply"
                    onClick={this.handleSearch}
                >
                    {gettext('Search')}
                </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.listFilters,
    listFilters: state.listFilters,
    startDate: state.listFilters.startDate
        ? moment(state.listFilters.startDate)
        : null,
    endDate: state.listFilters.endDate
        ? moment(state.listFilters.endDate)
        : null,
});

const mapDispatchToProps = (dispatch) => ({
    filterByQueryString: (query) => {
        dispatch(filterByQueryString(query));
    },
    filterByFilterSet: (querySet) => {
        dispatch(filterByFilterSet(querySet));
    },
    filterByStatus: (statusQuery) => {
        dispatch(filterByStatus(statusQuery));
    },
    filterByDates: (from, to) => {
        dispatch(filterByDateRange(from, to));
    },
});

const ListFiltersConnecter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListFilters);

export default ListFiltersConnecter;
