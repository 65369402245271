import { call, all, put, takeEvery } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { changeStationsRoutine } from 'pipes/cooling/ducks/cooling';
import {
    subscriptionsRoutine,
    STATE_KEY as SUBSCRIPTIONS_KEY,
} from 'stations/ducks/subscriptions';
import {
    subscribersRoutine,
    STATE_KEY as SUBSCRIBERS_KEY,
} from 'stations/ducks/subscribers';
import api from 'core/utils/api';

import { subscriptionSchema } from './fetch';

function* changeStationsAsync(param) {
    const { subscription, newStationsIds } = param.payload;
    try {
        const data = yield call(() =>
            api.stations.subscriptionChangeStations.post(
                { pk: subscription.id },
                { station_ids: newStationsIds },
            ),
        );
        const { entities, result } = normalize(data, subscriptionSchema);

        yield all([
            put(subscriptionsRoutine.delete(subscription.id)),
            put(
                subscriptionsRoutine.success({
                    byId: entities[SUBSCRIPTIONS_KEY],
                    allIds: [result],
                }),
            ),
            // Doesn't make a lot of sense here, but we have to call it to cause rerender
            put(
                subscribersRoutine.success({
                    byId: entities[SUBSCRIBERS_KEY],
                    allIds: null,
                }),
            ),
        ]);
    } catch (error) {
        yield put(changeStationsRoutine.failure(error));
    }
}

export function* watchChangeStations() {
    yield takeEvery(changeStationsRoutine.TRIGGER, changeStationsAsync);
}
