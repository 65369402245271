import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from 'react-fa/lib/Icon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import RenderPropConfirmModal from 'core/components/RenderPropConfirmModal';
import {
    solveDowntimeRoutine,
    downtimesSelectors,
} from 'parts/operations/ducks/downtimes';
import { downtimePropType } from 'parts/operations/propTypes';
import { gettext } from 'core/utils/text';

import styles from './Styles.scss';

const SolveDowntimeButton = ({ latestDowntime, solveDowntime }) => (
    <RenderPropConfirmModal
        title={gettext("Are you sure you'd like to solve the downtime?")}
        onConfirm={() => solveDowntime(latestDowntime)}
        modalContent={
            <p>
                {gettext('Work will be marked as "In Progress" status again.')}
            </p>
        }
    >
        {({ requestConfirm }) => (
            <button
                className={styles.solveDowntime}
                onClick={(e) => {
                    e.stopPropagation();
                    requestConfirm();
                }}
            >
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip">
                            {gettext('Mark as solved')}
                        </Tooltip>
                    }
                >
                    <Icon
                        className={styles.affixDowntime}
                        name="exclamation-circle"
                        aria-label={gettext('Mark as solved')}
                    />
                </OverlayTrigger>
            </button>
        )}
    </RenderPropConfirmModal>
);

SolveDowntimeButton.propTypes = {
    latestDowntime: downtimePropType,
    solveDowntime: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    latestDowntime: downtimesSelectors.getByWorkOrderId(
        state,
        ownProps.workOrderId,
    ),
});

export default connect(mapStateToProps, {
    solveDowntime: solveDowntimeRoutine,
})(SolveDowntimeButton);
