import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ListFilters from 'core/components/filters/ListFilters';
import { LoadingIndicator, ErrorState, ErrorFloat } from 'core/components';
import { gettext } from 'core/utils/text';

import { WorkOrder } from './components';
import styles from './PartsOperationsList.scss';

const PartsOperationsList = ({
    workOrderIds,
    isLoading,
    triggerFetchingViewData,
    reInitOperationsPageState,
    error = {},
}) => {
    useEffect(() => {
        triggerFetchingViewData();
    }, []);

    return (
        <div className={styles.container}>
            <h3>{gettext('Work scheduler')}</h3>
            <ListFilters
                disabled={isLoading}
                showDateFilter={false}
                beforeFilter={reInitOperationsPageState}
                canExportData={false}
                afterFilter={triggerFetchingViewData}
            />
            {(() => {
                if (isLoading) {
                    return <LoadingIndicator />;
                } else if (error) {
                    return (
                        <ErrorFloat>
                            <ErrorState code={error.statusCode} />
                        </ErrorFloat>
                    );
                } else if (!workOrderIds.length) {
                    return <p>{gettext('No work orders available!')}</p>;
                } else {
                    return workOrderIds.map((workOrderId) => (
                        <WorkOrder
                            key={workOrderId}
                            workOrderId={workOrderId}
                        />
                    ));
                }
            })()}
        </div>
    );
};

PartsOperationsList.propTypes = {
    workOrderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    isLoading: PropTypes.bool.isRequired,
    triggerFetchingViewData: PropTypes.func.isRequired,
    reInitOperationsPageState: PropTypes.func.isRequired,
    error: PropTypes.shape({
        statusCode: PropTypes.number,
    }),
};

export default PartsOperationsList;
